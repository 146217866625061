import { getCookie } from "./common";
import { FileUploadValidator } from "./common";
import { csrfSafeMethod, showLoader, hideLoader } from "./common";

// make an image consume full screen and darken the BG
function modalImage(img) {
    var modal = document.getElementById("imageModal");
    var modalImg = document.getElementById("modalImageContent");
    var captionText = document.getElementById("modalImageCaption");

    modal.style.display = "block";
    modalImg.src = img.src;
    captionText.innerHTML = img.alt;

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("closeModal")[0];

    // When the user clicks on <span> (x), close the modal
    span.onclick = function () {
        modal.style.display = "none";
    }

    modalImg.onclick = function () {
        modal.style.display = "none";
    }
}
// global can be called from template
window.modalImage = modalImage;


// confirm user action before deleting
function commentDelete(id) {
    if (confirm('Are you sure you want to PERMANENTLY Delete this comment?')) {
        window.location = `/social/commentdelete/${id}`;
    }
}
window.commentDelete = commentDelete;

// support for sprinkling feed_incl all over the place
$("#postForm").on('submit', function() {
    if(FileUploadValidator()) {
        return true;
    } else {
        return false;
    }
});

$("#btnPost").click(function () {
    let id = $(this).attr("data-segment-id");
    var theForm = $("form#postForm");
    theForm.find("> input#id_segment_id").val(id);

    $("#postModal").modal();
});

$("#modalPostConfirmButton").click(function () {
    $('#modalPostConfirmButton').prop('disabled', true);
    $("#postForm").submit();
});


$("#btnFollow").click(function () {
    handleFollowClick($(this));
});

let rowid = '';

$(".btnDeletePost").click(function () {
    rowid = $(this).attr("data-item-id");
    $("#deletePost").modal();
});

$("#modalPostDeleteConfirmButton").click(function () {
    if (rowid != '') {
        let strUrl = `/social/postdelete/${rowid}`;
        window.location.assign(strUrl);
    }
});

function newComment(thisObj) {
    let id = thisObj.parent().attr('data-item-id');
    var theForm = $('form#comment-form');
    theForm.find('> input#id_social_item').val(id);

    $('#commentDlg').modal();

}

// comment create dialog
// support rich content in comments
// $(".launchCommentDlg").click(function () {
//     let id = $(this).parent().attr("data-item-id");
//     var theForm = $("form#comment-form");
//     theForm.find("> input#id_social_item").val(id);

//     $("#commentDlg").modal();
// });

$("#commentConfirmBtn").click(function () {
    if (FileUploadValidator()) {
        $('#commentConfirmBtn').prop('disabled', true);
        showLoader();
        $("#comment-form").submit();
        hideLoader();
    }
});


// function handleCommentClick(thisObj) {
//     let element = thisObj;
//     let commentDiv = element.next();
//     if (commentDiv.hasClass('hidden')) {
//         commentDiv.removeClass('hidden').addClass('visible');
//     } else {
//         commentDiv.removeClass('visible').addClass('hidden');
//     }
// }

function handleLikeClick(thisObj) {
        let this_ = thisObj;
        let urldest = this_.attr('data-url');
        let likeType = this_.attr('data-like-item');
        let id = this_.attr('data-item-id');

        //  data-url="{% url 'toggle_like' %}" data-like-item="PST" data-item-id="{{item.id}}"
        let csrftoken = getCookie('csrftoken');
        let likeCounter = this_.find('> .likeCounter');

        $.ajaxSetup({
            beforeSend: function (xhr, settings) {
                if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                    xhr.setRequestHeader('X-CSRFToken', csrftoken);
                }
            },
        });

        $.ajax({
            method: 'POST',
            url: urldest,
            dataType: 'json',
            data: {
                like_type: likeType,
                id: id,
            },
            success: function (data) {
                //alert("message: " + data['message'] + ' ' + data['like_count']);
                showAlert(data['message'], 'alert-success');
                likeCounter.html(data['like_count']);
                if (data['like'] == true) {
                    this_.addClass('text-danger');
                } else {
                    this_.removeClass('text-danger');
                }
            },
            error: function (error) {
                var status = error.status;
                var message = error.responseJSON['error'];
                showAlert(message, 'alert-danger');
                console.log(message);
            },
        });
        // prevent the page from jumping
        return false;
}

// also handles un-follow through the data-url attribute
function handleFollowClick(thisObj) {
    let this_ = thisObj;
    let urldest = this_.attr('data-url');
    let followType = this_.attr('data-follow-item');
    let id = this_.attr('data-item-id');

    //<button class="btn btn-sm btn-primary btn-follow" data-url="{% url 'follow' %}" data-follow-item="USR" data-item-id="{{profile.account.id}}">Follow {{profile.screen_name}}</button>
    let csrftoken = getCookie('csrftoken');
    let likeCounter = this_.find('> .likeCounter');

    $.ajaxSetup({
        beforeSend: function (xhr, settings) {
            if (!csrfSafeMethod(settings.type) && !this.crossDomain) {
                xhr.setRequestHeader('X-CSRFToken', csrftoken);
            }
        },
    });

    $.ajax({
        method: 'POST',
        url: urldest,
        dataType: 'json',
        data: {
            follow_type: followType,
            id: id,
        },
        success: function (data) {
            showAlert(data['message'], 'alert-success');
        },
        error: function (error) {
            var status = error.status;
            var message = error.responseJSON['error'];
            showAlert(message, 'alert-danger');
            console.log(message);
        },
    });
    // prevent the page from jumping
    return false;

}



var infinite = new Waypoint.Infinite({
    element: $('.infinite-container')[0],
    offset: 'bottom-in-view',
    onBeforePageLoad: function () {
        showLoader();
    },
    onAfterPageLoad: function () {
        hideLoader();
        var videos = document.getElementsByTagName('video');

        if (videos) {
            for (var i = 0; i < videos.length; i++) {
                var video = videos[i];
                if (video.className.indexOf('video-js') > -1) {
                    //console.log(`found player....${video.id}`)
                    videojs(video.id).ready(function () {
                        var myPlayer = this;
                        //myPlayer.on('play', playVideo);
                        //myPlayer.on('pause', pauseVideo)
                    });
                }
            }
        }
    }
});

//window.handleCommentClick = handleCommentClick;
window.handleLikeClick = handleLikeClick;
window.handleFollowClick = handleFollowClick;
window.newComment = newComment;

export { modalImage, commentDelete };
