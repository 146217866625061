var showAlert = function (msg, myclass, div_id) {
    let alertClass = myclass;
    let theAlert = null;

    if (alertClass === undefined) {
        alertClass = 'alert-success';
    }

    if (alertClass !== 'alert-success') {
        theAlert = $('#alertDangerDiv');
    } else {
        theAlert = $('#alertSuccessDiv');
    }
    theAlert.html(msg);
    theAlert.removeClass('hidden').addClass('visible');

    setTimeout(function () {
        theAlert.html('');
        theAlert.removeClass('visible').addClass('hidden');
    }, 5000);
};
window.showAlert = showAlert;

function sec2time(timeInSeconds) {
    var pad = function (num, size) {
            return ('000' + num).slice(size * -1);
    },
    time = parseFloat(timeInSeconds).toFixed(3),
    hours = Math.floor(time / 60 / 60),
    minutes = Math.floor(time / 60) % 60,
    seconds = Math.floor(time - minutes * 60);
    //milliseconds = time.slice(-3);

    if (hours > 0) {
        return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2);
    } else {
        return pad(minutes, 2) + ':' + pad(seconds, 2);
    }
    //return pad(hours, 2) + ':' + pad(minutes, 2) + ':' + pad(seconds, 2) + ',' + pad(milliseconds, 3);
}
window.sec2time = sec2time;

// WFH
// given a string hh:mm:ss return the seconds
function time2sec(timeStr) {
    let totalParts = timeStr.split(':');
    let totalSecs = parseInt(totalParts[0]) * 60 * 60;
    totalSecs += parseInt(totalParts[1]) * 60;
    totalSecs += parseInt(totalParts[2]);
    return totalSecs;
}
window.time2sec = time2sec;

function getCookie(name) {
    var cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i].trim();
            // Does this cookie string begin with the name we want?
            if (cookie.substring(0, name.length + 1) === name + '=') {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}
window.getCookie = getCookie;

function csrfSafeMethod(method) {
    // these HTTP methods do not require CSRF protection
    return /^(GET|HEAD|OPTIONS|TRACE)$/.test(method);
}
window.csrfSafeMethod = csrfSafeMethod;

function FileUploadValidator() {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
        // this is how django / crispy does the field.  You can't just go after the
        // input field by id.  Files is null.
        // now supports multiple checks per form submit
        var container = $('.custom-file-input');
        var finput = container[0].files[0];
        var okToCommit = true;

        // if (finput == null) {
        //     return okToCommit;
        // }
        // 0: title_image
        // 1: video file
        // 2: title_image
        // 3: video file
        for (let i = 0; i < container.length; i++) {
            let fi = container[i].files[0];
            if (fi != null) {
                // let fname = fi.name.toLowerCase();
                // let ext = fname.split('.').pop();
                let ftype = fi.type;
                let field = container[i].name;
                if (field === 'title_image' || field === 'cmt_title_image') {
                    if (ftype !== 'image/jpeg' && ftype !== 'image/png' && ftype !== 'image/gif') {
                        okToCommit = false;
                        alert('You submitted a file of type: ' + ftype + ' it should be an image (jpg, png, gif)');
                        return okToCommit;
                    }
                } else if (field === 'video_file' || field === 'cmt_video_file') {
                    // support eliminating multiple file type upload fields.
                    // view code will sort it out
                    if (
                        ftype !== 'video/mp4' &&
                        ftype !== 'image/jpeg' &&
                        ftype !== 'image/png' &&
                        ftype !== 'image/gif'
                    ) {
                        okToCommit = false;
                        alert(
                            'You submitted a file of type: ' +
                                ftype +
                                ' it should be a video (mov, mp4) or an image (jpeg, png, gif)'
                        );
                        return okToCommit;
                    } else {
                    }
                } else if (field === 'lesson_file') {
                    // this field is dependent on the setting of the file type manually. So, no checking for now.
                    okToCommit = true;
                }
            }
        }

        for (let i = 0; i < container.length; i++) {
            let fi = container[i].files[0];
            if (fi != null) {
                if (fi.size > 0) {
                    const fsize = fi.size;
                    const filesize = Math.round(fsize / 1024); // kilo bytes
                    // The size of the file. Compare in Kilobytes
                    // 500 mb
                    if (filesize >= 512000) {
                        okToCommit = false;
                        alert(
                            'The file is too Big, please select a file less than 500mb.  Try shortening the video, lowering the resolution, or lowering the frame rate.'
                        );
                        break;
                    } else {
                        okToCommit = true;
                    }
                }
            }
        }
        return okToCommit;
    }
}


function setIFrameSrc(url) {
    let iFrame = document.getElementById('ld-iframe');
    let iFrameModal = document.getElementById('iframe-modal');
    let iFrameModalClose = document.getElementById('iframe-modal-close');
    
    iFrame.src = url;
    iFrame.style.visibility = 'visible';
    iFrameModal.style.visibility = 'visible';
    iFrameModalClose.style.visibility = 'visible';
}

function closeIFrame() {
    let iFrame = document.getElementById('ld-iframe');
    let iFrameModal = document.getElementById('iframe-modal');
    let iFrameModalClose = document.getElementById('iframe-modal-close');
    
    iFrame.style.visibility = 'hidden';
    iFrameModal.style.visibility = 'hidden';
    iFrameModalClose.style.visibility = 'hidden';
    $('.loader').hide();
    iFrame.src = '';
}

// called from inside the iframe on lesson detail when all are marked as read.
// references the partent of the iframe which is the LessonDetail page.
function closeParentIFrame() {
    let iFrame = window.parent.document.getElementById('ld-iframe');
    let iFrameModal = window.parent.document.getElementById('iframe-modal');
    let iFrameModalClose = window.parent.document.getElementById('iframe-modal-close');
    
    iFrame.style.visibility = 'hidden';
    iFrameModal.style.visibility = 'hidden';
    iFrameModalClose.style.visibility = 'hidden';
    $('.loader').hide();
    iFrame.src = '';
    window.parent.location.reload();
}

function showLoader() {
    $('.loader').css('display', 'block');
}

function hideLoader() { 
    $('.loader').css('display', 'none');        
}


window.FileUploadValidator = FileUploadValidator;
window.setIFrameSrc = setIFrameSrc;
window.closeIFrame = closeIFrame;
window.closeParentIFrame = closeParentIFrame;
window.showLoader = showLoader;
window.hideLoader = hideLoader;


export { showAlert, sec2time, time2sec, getCookie, csrfSafeMethod, FileUploadValidator, showLoader, hideLoader };
